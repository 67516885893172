import React from "react";
import styles from "./Chips.module.css";
import stylem from "./index.module.css";
import { GlobalContext } from "../../context/GlobalContext";
import Avatar from "../../assets/metabot.png";

export default function Chips({ value }) {
  const { question, answer } = value;
  return (
    <div
      className={`${stylem.Message} d-flex justify-content-start align-items-start`}
    >
      <div className={stylem.Avatar}>
        <img src={Avatar} alt="bot" />
      </div>
      <div className={`${stylem.MessageText}`}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 15.5 15.5"
          enableBackground="new 0 0 15.5 15.5"
        >
          <polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " />
        </svg>
        {question && <div>{question}</div>}
      </div>
    </div>
  );
}
