import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "./index.module.css";
import { GlobalContext } from "../../context/GlobalContext";

export default function Suggestions() {
  const { suggestions, emitEvent } = React.useContext(GlobalContext);

  function renderSuggestions(suggestions) {
    return suggestions.map((suggestion, i) => {
      const { type, label, value } = suggestion;
      if (!label) return "";
      if (type === "link") {
        return (
          <a
            key={i}
            href={value}
            target="_blank"
            rel="noreferrer"
            className="btn"
          >
            {label}
          </a>
        );
      } else {
        return (
          <button
            key={i}
            type="button"
            className="btn"
            onClick={(e) => {
              emitEvent({
                type: type ? type : "text",
                value,
                label: label ? label : "",
              });
            }}
          >
            {label}
          </button>
        );
      }
    });
  }

  const renderThumb = () => (
    <div style={{ backgroundColor: "#ea8423", borderRadius: "20px" }} />
  );
  const renderTrack = () => (
    <div style={{ backgroundColor: "#ea8423", borderRadius: "20px" }} />
  );

  return (
    <div className={styles.Suggestions}>
      <Scrollbars
        renderThumbHorizontal={renderThumb.bind(this)}
        renderTrackHorizontal={renderTrack.bind(this)}
      >
        <div className={styles.SuggestionsWrap}>
          {renderSuggestions(suggestions)}
        </div>
      </Scrollbars>
    </div>
  );
}
