export const findVal = (obj, keyToFind) => {
  if (obj[keyToFind]) return obj[keyToFind];
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      const value = findVal(obj[key], keyToFind);
      if (value) return value;
    }
    return null;
  }
};

export const resolveAfterXSeconds = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const sanitize = (string) => {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'/]/gi;
  return string.replace(reg, (match) => map[match]);
};
