import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "./index.module.css";
import Message from "./Message";
import Chips from "./Chips";
import Ref from "./Ref";
import Typing from "./Typing";
import Videos from "./Videos";
import Articles from "./Articles";
import Courses from "./Courses";
import { GlobalContext } from "../../context/GlobalContext";

export default function Messages() {
  const rcsRef = React.useRef(null);

  const { messages, suggestions } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (typeof window !== "undefined" && rcsRef.current) {
      rcsRef.current.scrollToBottom();
    }
  });

  const renderThumb = () => (
    <div style={{ backgroundColor: "rgba(255,255,255,0.1)", borderRadius: "20px" }} />
  );

  function renderMessages(messages) {
    return messages.map((item, index) => {
      for (const [key, val] of Object.entries(item.value)) {
        // console.log(key, val);
        switch (key) {
          case "delay":
            return <Typing key={index} speaks={item.speaks} value={val} />;
          case "text":
            return <Message key={index} speaks={item.speaks} value={val} />;
          case "chip":
            return <Chips key={index} speaks={item.speaks} value={val} />;
          case 'ref':
            return <Ref key={index} speaks={item.speaks} value={val} />;
          case "videos":
            return <Videos key={index} speaks={item.speaks} value={val} />;
          case "articles":
            return <Articles key={index} speaks={item.speaks} value={val} />;
          case "courses":
            return <Courses key={index} speaks={item.speaks} value={val} />;
          default:
            return "";
        }
      }
      return "";
    });
  }

  return (
    <div
      className={`${styles.MessageBox} ${
        suggestions.length > 0 && styles.WithSuggestions
      }`}
    >
      <Scrollbars ref={rcsRef} renderThumbVertical={renderThumb.bind(this)}>
        <div className={styles.MessageContent}>{renderMessages(messages)}</div>
      </Scrollbars>
    </div>
  );
}
