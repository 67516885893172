export function Refresh() {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 22 22"
      enableBackground="new 0 0 22 22"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#AFACAC"
          d="M21.6,11.8c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h0c-0.3,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.4,0.4-0.4,0.7c-0.3,1.6-1,3.2-2.1,4.4c-1.1,1.2-2.6,2.1-4.2,2.5c-1.7,0.5-3.5,0.4-5.2-0.2c-1.7-0.6-3.1-1.7-4.1-3.2
                        c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1h2.1c0.2,0,0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.6-0.2H0V20c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.6,0.2
                        c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.6v-2.1c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0
                        c0.1,0,0.1,0.1,0.2,0.1c1.3,1.7,3.1,3,5.1,3.7c2,0.7,4.2,0.8,6.3,0.3c2.1-0.5,4-1.7,5.4-3.3c1.4-1.6,2.4-3.6,2.7-5.7
                        c0-0.2,0-0.3,0-0.5C21.8,12.1,21.7,11.9,21.6,11.8L21.6,11.8z"
        />
        <path
          fill="#AFACAC"
          d="M15.7,8.3H22V2c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
                        c-0.1,0.1-0.2,0.3-0.2,0.6v2.1c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1
                        c-1.3-1.7-3.1-3-5.1-3.7c-2-0.7-4.2-0.8-6.3-0.3C6.2,0.9,4.3,2,2.9,3.6S0.5,7.1,0.1,9.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.3,0.2,0.4
                        c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.4,0.4-0.7c0.3-1.7,1.1-3.3,2.2-4.5
                        c1.2-1.3,2.7-2.1,4.4-2.5c1.7-0.4,3.4-0.2,5,0.4c1.6,0.6,3,1.7,4,3.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2
                        c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1h-2.1c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
                        C15.3,8.2,15.5,8.3,15.7,8.3z"
        />
      </g>
    </svg>
  );
}
export function Save() {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 25 25"
      enableBackground="new 0 0 25 25"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#AFACAC"
          d="M24.8,4.7l-4.5-4.5C20.2,0.1,20,0,19.9,0H2.3C1,0,0,1,0,2.3v20.5C0,24,1,25,2.3,25h20.5
                        c1.3,0,2.3-1,2.3-2.3V5.1C25,5,24.9,4.8,24.8,4.7z M5.7,1.1h12.5V8c0,0.6-0.5,1.1-1.1,1.1H6.8C6.2,9.1,5.7,8.6,5.7,8V1.1z
                         M20.5,23.9H4.5V13.6h15.9V23.9z M23.9,22.7c0,0.6-0.5,1.1-1.1,1.1h-1.1V13.1c0-0.3-0.3-0.6-0.6-0.6H4c-0.3,0-0.6,0.3-0.6,0.6v10.8
                        H2.3c-0.6,0-1.1-0.5-1.1-1.1V2.3c0-0.6,0.5-1.1,1.1-1.1h2.3V8c0,1.3,1,2.3,2.3,2.3H17c1.3,0,2.3-1,2.3-2.3V1.1h0.3l4.2,4.2V22.7z"
        />
        <path
          fill="#AFACAC"
          d="M14.2,8h2.3C16.8,8,17,7.7,17,7.4V2.8c0-0.3-0.3-0.6-0.6-0.6h-2.3c-0.3,0-0.6,0.3-0.6,0.6v4.5
                        C13.6,7.7,13.9,8,14.2,8z M14.8,3.4h1.1v3.4h-1.1V3.4L14.8,3.4z"
        />
      </g>
    </svg>
  );
}
export function Plane() {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 23.4"
      enableBackground="new 0 0 24 23.4"
      xmlSpace="preserve"
    >
      <path
        fill="#AFACAC"
        d="M23.7,10.6c-0.2-0.5-0.7-0.9-1.2-1.2l-19-9.2c-1.1-0.6-2.6,0-3.2,1.2C0,2,0,2.8,0.2,3.4l3.3,8.2l-3.3,8.2
                  c-0.5,1.3,0.1,2.8,1.4,3.3c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.3l19-9.2C23.8,13.4,24.4,11.8,23.7,10.6z M2.9,21.6
                  c-0.4,0.2-0.9,0-1.1-0.4c-0.1-0.2-0.1-0.5,0-0.7l3.2-8h16.7L2.9,21.6z M4.9,10.9l-3.2-8c-0.1-0.4,0-0.8,0.2-1s0.6-0.3,0.9-0.1
                  l18.8,9.1C21.6,10.9,4.9,10.9,4.9,10.9z"
      />
    </svg>
  );
}

export function Phone() {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 45 45"
      enableBackground="new 0 0 45 45"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#EB8424"
          d="M22.5,45C10.09,45,0,34.91,0,22.5C0,10.09,10.09,0,22.5,0C34.91,0,45,10.09,45,22.5
              C45,34.91,34.91,45,22.5,45z M22.5,2C11.2,2,2,11.2,2,22.5S11.2,43,22.5,43S43,33.8,43,22.5S33.8,2,22.5,2z"
        />
        <path
          fill="#EB8424"
          d="M32.61,28.08l-2.22-1.4l-1.97-1.25c-0.38-0.24-0.89-0.16-1.17,0.18l-1.22,1.49
              c-0.26,0.32-0.73,0.42-1.11,0.22c-0.83-0.44-1.81-0.85-3.97-2.9c-2.16-2.05-2.6-2.98-3.06-3.76c-0.21-0.36-0.11-0.8,0.23-1.05
              l1.57-1.16c0.36-0.27,0.44-0.75,0.19-1.11l-1.27-1.81l-1.52-2.16c-0.26-0.37-0.78-0.48-1.18-0.25l-1.75,1
              c-0.47,0.26-0.82,0.69-0.97,1.19c-0.48,1.66-0.58,5.31,5.6,11.16c6.17,5.85,10.02,5.76,11.77,5.31c0.53-0.14,0.98-0.47,1.26-0.92
              l1.05-1.66C33.12,28.82,33,28.33,32.61,28.08z"
        />
      </g>
    </svg>
  );
}
export function Email() {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 45 45"
      enableBackground="new 0 0 45 45"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#EB8424"
          d="M22.5,45C10.09,45,0,34.91,0,22.5C0,10.09,10.09,0,22.5,0C34.91,0,45,10.09,45,22.5
              C45,34.91,34.91,45,22.5,45z M22.5,2C11.2,2,2,11.2,2,22.5S11.2,43,22.5,43S43,33.8,43,22.5S33.8,2,22.5,2z"
        />
        <path
          fill="#EB8424"
          d="M32.07,15H13.93C12.87,15,12,15.87,12,16.93v11.6c0,1.07,0.87,1.93,1.93,1.93h18.13
              c1.06,0,1.93-0.86,1.93-1.93v-11.6C34,15.87,33.14,15,32.07,15z M31.8,16.29c-0.4,0.39-7.19,7.16-7.43,7.39
              C24,24.04,23.52,24.24,23,24.24s-1-0.2-1.37-0.57c-0.16-0.16-6.88-6.85-7.43-7.39H31.8z M13.29,28.27V17.2l5.57,5.54L13.29,28.27z
               M14.21,29.18l5.57-5.53l0.95,0.94c0.61,0.61,1.42,0.94,2.28,0.94s1.67-0.34,2.28-0.94l0.95-0.94l5.57,5.53H14.21z M32.71,28.27
              l-5.57-5.53l5.57-5.54V28.27z"
        />
      </g>
    </svg>
  );
}
