import React from "react";
import { Helmet } from "react-helmet-async";
import { useIdleTimer } from "react-idle-timer";
import { ToastContainer } from "react-toastify";
import { GlobalContext } from "./context/GlobalContext";
import styles from "./App.module.css";
import Welcome from "./components/Welcome";
import Conversation from "./components/Conversation";
import Planetmedia from "./assets/planetmedia-logo.svg";

const App = () => {
  const { socket, user, start } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (socket.connected) {
      console.log("io connected");
    } else {
      console.log("io not connected");
    }
  }, [socket]);

  const [documentTitle, setDocumentTitle] = React.useState(
    "Metaverse - Planet Media"
  );

  const [chatFocus, setChatFocus] = React.useState(true);
  const [userActive, setUserActive] = React.useState(true);

  const handleOnIdle = (event) => {
    setDocumentTitle(`We miss you ${user.name ? user.name : ""} 😢`);
    setChatFocus(false);
    setUserActive(false);
  };

  const handleOnActive = (event) => {
    setDocumentTitle(`Welcome back ${user.name ? user.name : ""} 😍`);
    setChatFocus(true);
  };

  useIdleTimer({
    timeout: 1000 * 20,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  if (chatFocus && !userActive) {
    setTimeout(() => {
      setDocumentTitle("Metaverse - Planet Media");
      setUserActive(true);
    }, 2000);
  }

  return (
    <div className={styles.App}>
      <Helmet lang="en">
        <meta charSet="utf-8" />
        <title>{documentTitle}</title>
      </Helmet>
      <ToastContainer
        toastStyle={{ backgroundColor: "#190c40", color: "#ffffff" }}
        theme="colored"
        autoClose={3000}
        hideProgressBar
      />
      {start ? <Conversation /> : <Welcome />}
      <footer
        className={`${styles.Footer} ${user.uuid ? styles.FooterInner : ""} 
        ${user.uuid ? styles.FooAway : null}
        `}
      >
        <div className="container d-flex justify-content-center align-items-center">
          Powered by{" "}
          <a
            href="https://planetmedia.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Planetmedia} alt="Planet Media" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default App;
