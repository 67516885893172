import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Video from "./Video";
import styles from "./Videos.module.css";
import stylem from "./index.module.css";
import Avatar from "../../assets/metabot.png";

export default function Videos({ value }) {
  const renderThumb = () => (
    <div
      style={{
        backgroundColor: "#ea8423",
        borderRadius: "20px",
        height: "6px",
      }}
    />
  );
  function renderVideos(videos) {
    return videos.map((video, i) => {
      return <Video key={i} video={video} />;
    });
  }
  return (
    <div
      className={`${stylem.Message} d-flex justify-content-start align-items-start`}
    >
      <div className={stylem.Avatar}>
        <img src={Avatar} alt="bot" />
      </div>
      <div className={stylem.MessageArea}>
        <div className={`${stylem.MessageText}`}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 15.5 15.5"
            enableBackground="new 0 0 15.5 15.5"
          >
            <polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " />
          </svg>
          <div className={styles.VideoList}>
            <Scrollbars renderThumbHorizontal={renderThumb.bind(this)}>
              <div className="d-flex w-100">{renderVideos(value)}</div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
}
