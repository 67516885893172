import React from "react";
import Modal from "react-modal";
import styles from "./Article.module.css";

const customStyles = {
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    inset: "0px",
    padding: "10px",
    borderRadius: "0px",
    backgroundColor: "#190c40",
    borderColor: "#190c40",
  },
};
Modal.setAppElement("#root");

export default function Article({ article }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <article className={styles.Article} onClick={openModal}>
        {article.image && (
          <figure>
            <img src={article.image} alt={article.title} />
          </figure>
        )}
        <div className={styles.Title}>{article.title}</div>
        <p>
          {article.description.replace(/<\/?[^>]+(>|$)/g, "").substr(0, 80) +
            "..."}
        </p>
      </article>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={article.title}
      >
        <div className="container">
          <div className={styles.ModalArticle}>
            <button
              onClick={closeModal}
              className={styles.Close}
              aria-label="Close Modal"
            ></button>
            {article.image && (
              <figure className={styles.ModalFigure}>
                <img src={article.image} alt={article.title} />
              </figure>
            )}
            <div className={styles.ModalTitle}>{article.title}</div>
            <div
              dangerouslySetInnerHTML={{ __html: article.description }}
            ></div>
          </div>
        </div>
      </Modal>
    </>
  );
}
