import React from "react";
// import { useCookies } from "react-cookie";
import { useSpring } from "react-spring";
import Chatbox from "./Chatbox";
import styles from "./Conversation.module.css";
import { GlobalContext } from "../context/GlobalContext";

export default function Conversation() {

  const { user } = React.useContext(GlobalContext);

  // const [cookies, setCookie, removeCookie] = useCookies(["uuid", "rtoken"]);

  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MjgwMDE1NDQsImV4cCI6MzI1NjAwNjY4OH0.3N82CdB_8WVQe5f_w7eVYdt0rTzNAfFI8F2HvyPC7r4";
  // const socket = io(`ws://127.0.0.1:5000`, {
  //   transports: ["websocket"],
  //   // pingInterval: 1000 * 60 * 5,
  //   // pingTimeout: 1000 * 60 * 3,
  //   auth: {
  //     token: token,
  //   },
  //   query: {
  //     uuid: cookies.uuid,
  //     name: name,
  //   },
  // });

  // const [isConnected, setIsConnected] = React.useState(socket.connected);

  const fadeIn = useSpring({ opacity: 1 });

  // React.useEffect(() => {
  //   const socketConnect = (data) => {
  //     console.log(data);
  //     setIsConnected(true);
  //   }
  //   const socketDisconnect = (data) => {
  //     console.log(data);
  //     setIsConnected(true);
  //   }
  //   socket.on("connect", socketConnect);
  //   socket.on("disconnect", socketDisconnect);

  //   return () => {
  //     socket.off('connect', socketConnect);
  //     socket.off('disconnect', socketDisconnect);
  //   }
  // },[]);

  return (
    <div className="container">
      <div className={styles.Conversation} style={fadeIn}>
        <Chatbox />
      </div>
    </div>
  );
}
