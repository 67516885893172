import React from "react";
import styles from "./Ref.module.css";
import stylem from "./index.module.css";
import Avatar from "../../assets/metabot.png";
// import { GlobalContext } from "../../context/GlobalContext";

export default function Ref({ value }) {
  const { question } = value;
  // const { emitEvent } = React.useContext(GlobalContext);
  // function renderRef(refs) {
  //   return refs.map((ref, i) => {
  //     const { type, label, value } = ref;
  //     if (type === "link") {
  //       return (
  //         <a
  //           key={i}
  //           href={value}
  //           target="_blank"
  //           rel="noreferrer"
  //           className={styles.Button}
  //           style={{ marginBottom: "10px" }}
  //         >
  //           {label}
  //         </a>
  //       );
  //     } else {
  //       return (
  //         <button
  //           key={i}
  //           type="button"
  //           className={styles.Button}
  //           style={{ marginBottom: "10px" }}
  //           onClick={(e) => {
  //             console.log(ref.type, ref.value);
  //             emitEvent({ type, value, label: label ? label : "" });
  //           }}
  //         >
  //           {label ? label : value}
  //         </button>
  //       );
  //     }
  //   });
  // }
  return (
    <div
      className={`${stylem.Message} d-flex justify-content-start align-items-start`}
    >
      <div className={stylem.Avatar}>
        <img src={Avatar} alt="bot" />
      </div>
      <div className={`${stylem.MessageText}`}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 15.5 15.5"
          enableBackground="new 0 0 15.5 15.5"
        >
          <polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " />
        </svg>
        {question && <div>{question}</div>}
      </div>
    </div>
  );
}
