import * as React from "react";
import ReactDOM from "react-dom";
import * as ReactDOMClient from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import App from "./App";
import GlobalContextProvider from "./context/GlobalContext";
import reportWebVitals from "./reportWebVitals";

const root = document.getElementById("root");

export const Render = () => (
  // <React.StrictMode>
    <CookiesProvider>
      <HelmetProvider>
        <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
      </HelmetProvider>
    </CookiesProvider>
  // </React.StrictMode>
);

module.hot
  ? ReactDOMClient.createRoot(root).render(<Render />)
  : ReactDOM.hydrate(<Render />, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
