import React from "react";
// import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import styles from "./Welcome.module.css";
import { sanitize } from "../utils";
import { GlobalContext } from "../context/GlobalContext";
import MetaBot from "../assets/metabot.png";
import Arrow from "../assets/arrow.svg";
import Keyboard from "../assets/keyboard.svg";

const Welcome = () => {
  const refName = React.useRef(null);
  const refEmail = React.useRef(null);
  const { setUser } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    name: "",
    email: "",
  });

  const validateEmail = (email) => {
    const regx = /\S+@\S+\.\S+/;
    return regx.test(email);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (state.name.length < 3) {
      refName.current.focus();
      toast.warn("Please enter your name", {
        position: "top-center",
        pauseOnHover: true,
        draggable: true,
      });
    } else if (state.email === "" || validateEmail(state.email) === false) {
      refEmail.current.focus();
      toast.warn("Please enter your email id", {
        position: "top-center",
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setUser({
        name: state.name,
        email: state.email,
        uuid: uuidv4(),
      });
    }
  };

  return (
    <section className={styles.Welcome}>
      <div className={styles.Content}>
        <div className={styles.WelcomeImage}>
          <img src={MetaBot} alt="MetaBot" />
          <div className={styles.WelcomeBubble}>
            Hi, I'm <span>Jane</span>.
            <br />
            Your Metaverse assistant.
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 15.5 15.5"
              enableBackground="new 0 0 15.5 15.5"
            >
              <polygon fill="#FFFFFF" points="15.5,0 0,15.5 15.5,15.5 " />
            </svg>
          </div>
        </div>
        <form onSubmit={submitHandler.bind(this)}>
          <div
            className={`${styles.WelcomeForm} d-sm-flex align-items-center justify-content-center`}
          >
            <input
              type="text"
              placeholder="Please enter your name"
              className={styles.WelcomeInput}
              ref={refName}
              onChange={(e) => {
                const name = sanitize(e.target.value);
                setState({ ...state, name });
              }}
            />
            <input
              type="email"
              placeholder="Enter your email address"
              className={styles.WelcomeInput}
              ref={refEmail}
              onChange={(e) => {
                const email = sanitize(e.target.value);
                setState({ ...state, email });
              }}
            />
          </div>
          <p className={`${styles.WelcomeText}`}>How may I help you today?</p>
          <div
            className={`${styles.WelcomeAction} d-flex align-items-center justify-content-center`}
          >
            <button
              type="submit"
              className={`${styles.ButtonIcon} d-flex align-items-center justify-content-center`}
            >
              <img src={Keyboard} alt="Start Chat" />
            </button>
            <button type="submit" className={styles.ButtonRGB}>
              <div className={styles.Green}></div>
              <div className={styles.Pink}></div>
              <div className={styles.Blue}></div>
            </button>
            <button
              type="submit"
              className={`${styles.ButtonIcon} d-flex align-items-center justify-content-center`}
            >
              <img src={Arrow} alt="Start Chat" />
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Welcome;
