import React from "react";
import { Spring, animated } from "react-spring";
import { resolveAfterXSeconds } from "../../utils";
import styles from "./Typing.module.css";
import stylem from "./index.module.css";
import Avatar from "../../assets/metabot.png";
import Pencil from "../../assets/pencil-fill.svg";

const Typing = ({ value }) => {
  const [visible, setVisible] = React.useState(true);

  (async function () {
    await resolveAfterXSeconds(value);
    setVisible(false);
  })();

  return (
    <Spring opacity={visible ? 1 : 0}>
      {(style) => (
        <div className={styles.Typing}>
          <animated.div style={style}>
            <div className={`d-flex align-items-center`}>
              <img src={Pencil} alt="pecil" className={styles.Pencil} />
              <div className={styles.Avatar}>
                <img src={Avatar} alt="bot" />
              </div>
              <div className="d-flex align-items-end">
                <span className={styles.Name}>Jane</span> is typing{" "}
                <div className={styles.Pulse} />
              </div>
            </div>
          </animated.div>
        </div>
      )}
    </Spring>
  );
  // return (
  //   <div
  //     className={`${stylem.Message} d-flex justify-content-start align-items-start`}
  //   >
  //     <div className={stylem.Avatar}>
  //       <img src={Avatar} alt="bot" />
  //     </div>
  //     <div className={`${stylem.MessageText} ${styles.Indicator}`}>
  //       <svg
  //         version="1.1"
  //         xmlns="http://www.w3.org/2000/svg"
  //         x="0px"
  //         y="0px"
  //         viewBox="0 0 15.5 15.5"
  //         enableBackground="new 0 0 15.5 15.5"
  //       >
  //         <polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " />
  //       </svg>
  //       <div className={styles.Pulse} />
  //     </div>
  //   </div>
  // );
};

export default Typing;
