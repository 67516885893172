import React from "react";
import { io } from "socket.io-client";
// import { toast } from "react-toastify";
import { GlobalReducer } from "./GlobalReducer";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MjgwMDE1NDQsImV4cCI6MzI1NjAwNjY4OH0.3N82CdB_8WVQe5f_w7eVYdt0rTzNAfFI8F2HvyPC7r4";
const socket = io(
  process.env.REACT_APP_SOCKET || "wss://metaverse-planetmedia.herokuapp.com",
  {
    transports: ["websocket"],
    // pingInterval: 1000 * 60 * 5,
    // pingTimeout: 1000 * 60 * 3,
    auth: {
      token: token,
    },
    // query: {
    //   token: token
    // }
  }
);

const initialState = {
  start: false,
  typing: false,
  user: {
    uuid: "",
    name: "",
  },
  messages: [],
  suggestions: [],
  socket,
};

export const GlobalContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(GlobalReducer, initialState);
  const setTyping = (payload) => {
    dispatch({ type: "SET_TYPING", payload });
  };
  const setUser = (payload) => {
    dispatch({ type: "SET_USER", payload });
  };
  const clearUser = (payload) => {
    dispatch({ type: "CLEAR_USER", payload });
  };
  const setMessage = (payload) => {
    dispatch({ type: "SET_MESSAGE", payload });
  };
  const getMessage = (payload) => {
    dispatch({ type: "GET_MESSAGE", payload });
  };
  const clearMessage = (payload) => {
    dispatch({ type: "CLEAR_MESSAGE", payload });
  };
  const setSuggestion = (payload) => {
    dispatch({ type: "SET_SUGGESTION", payload });
  };
  const getSuggestion = (payload) => {
    dispatch({ type: "GET_SUGGESTION", payload });
  };
  const clearSuggestion = (payload) => {
    dispatch({ type: "CLEAR_SUGGESTION", payload });
  };
  const emitEvent = async (payload) => {
    const { type, value, label } = payload;
    const { user } = state;
    // console.log(type, value);
    // console.log(user);
    socket.emit("chat", {
      type,
      query: value,
      parameters: {
        person: user.name,
      },
    });
    dispatch({
      type: "EMIT_MESSAGE",
      payload: {
        type: "message",
        speaks: "me",
        value: {
          text: label ? label : value,
        },
      },
    });
  };
  // const handleEvent = async (payload) => {
  //   let response = await createIntentHandler(payload);
  //   console.log("createIntent", response);
  //   if (!response.ok) {
  //     toast.error(`${response.statusText}`);
  //     return response;
  //   }
  //   response = await response.json();
  //   if (response.status) {
  //     toast.success(`Conversation "${payload.title}" is created.`);
  //     dispatch({ type: "CREATE_INTENT", payload });
  //   } else {
  //     toast.warn("Something went wrong. Please try again later.");
  //   }
  //   return response;
  // };

  const contextValues = {
    clearUser,
    setTyping,
    setUser,
    setMessage,
    getMessage,
    clearMessage,
    setSuggestion,
    getSuggestion,
    clearSuggestion,
    emitEvent,
    ...state,
  };

  return (
    <GlobalContext.Provider value={contextValues}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
