// import { toast } from "react-toastify";
// import { canUseDOM } from "../utils";

// export const deleteIntentHandler = async (id) => {
//   console.log("deleting.......", id);
//   const apiURL = `${process.env.REACT_APP_API_URL}/api/v1/intents`;
//   return await fetch(apiURL, {
//     method: "DELETE",
//     headers: {
//       "Content-Type": "application/json",
//       "X-CSRF-Token": csrf,
//     },
//     body: JSON.stringify({ id }),
//   });
// };

// export const getEventsHandler = async () => {
//   console.log("getEventsHandler.......");
//   const apiURL = `${process.env.REACT_APP_API_URL}/api/v1/intents/event/list`;
//   return await fetch(apiURL, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "X-CSRF-Token": csrf,
//     },
//   });
// };

export const GlobalReducer = (state, action) => {
  // console.info("GlobalReducer", state, action.payload);

  switch (action.type) {
    case "SET_TYPING":
      return {
        ...state,
        typing: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
        start: true,
      };
    case "CLEAR_USER":
      return {
        ...state,
        user: {
          uuid: "",
          name: "",
        },
        start: false,
        messages: [],
        suggestions: [],
      };
    case "SET_MESSAGE":
      // console.log("FETCH_INTENT", state, action.payload);

      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case "EMIT_MESSAGE":
      // console.log("EMIT_MESSAGE", state, action.payload);
      return {
        ...state,
        messages: [...state.messages, action.payload],
        suggestions: [],
      };
    case "CLEAR_MESSAGE":
      // console.log("CLEAR_MESSAGE", state, action.payload);

      return {
        ...state,
        messages: [],
        suggestions: [],
      };
    case "SET_SUGGESTION":
      // console.log("SET_SUGGESTION", state, action.payload);
      return {
        ...state,
        suggestions: action.payload,
      };
    case "CLEAR_SUGGESTION":
      // console.log("CLEAR_SUGGESTION", state, action.payload);
      return {
        ...state,
        suggestions: [],
      };
    default:
      return state;
  }
};
