import React from "react";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { resolveAfterXSeconds, sanitize } from "../utils";
import styles from "./Chatbox.module.css";
import Messages from "./Messsages";
import Suggestions from "./Suggestions";
import { Save, Refresh, Plane } from "../icons";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../context/GlobalContext";

export default function Chatbox() {
  const [tooltip, showTooltip] = React.useState(true);
  const textInput = React.useRef(null);
  const [text, setText] = React.useState("");

  const {
    socket,
    user,
    clearUser,
    messages,
    setMessage,
    suggestions,
    setSuggestion,
    emitEvent,
    typing,
    setTyping,
  } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (socket.connected) {
      console.log("io connected");
    } else {
      toast.dark("🦄 Looks like you have an unstable network at the moment", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("io not connected");
    }

    socket.emit("chat", {
      type: "join",
      query: "welcome-event",
      uuid: user.uuid,
      parameters: {
        person: user.name,
        email: user.email,
      },
    });
  }, [user, socket]);

  const responseMessage = React.useCallback(async (data) => {
    setTyping(true);
    const { type, speaks, messages } = data;

    if (data.type === "message") {
      for (let value of messages) {
        for (const [key, val] of Object.entries(value)) {
          if (key === "delay") {
            setMessage({
              speaks,
              type,
              value,
            });
            await resolveAfterXSeconds(parseInt(val) + 500);
          } else if (key === "chip") {
            if (val.question !== "") {
              setMessage({
                speaks,
                type,
                value,
              });
            }

            setSuggestion(val.answer);
          } else if (key === "ref") {
            if (val.question !== "") {
              setMessage({
                speaks,
                type,
                value,
              });
            }
            const newSuggesstion = [];
            val?.answer.forEach((item) => {
              if (item.label !== "") {
                newSuggesstion.push(item);
              }
            });

            if (Object.values(newSuggesstion).length > 0) {
              setSuggestion(val.answer);
            }
          } else {
            setMessage({
              speaks,
              type,
              value,
            });
          }
        }
      }
    } else if (data.type === "error") {
      toast.error(data.message, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setTyping(false);
  }, []);

  React.useEffect(() => {
    textInput.current.focus();

    socket.on("message", responseMessage);
    // socket.on("error", responseError);

    return () => {
      // socket.off("connect");
      socket.off("message");
      // socket.off("user");
      // socket.off("error");
      // socket.off("disconnect");
    };
  }, [socket]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!text) return;
    emitEvent({
      type: "text",
      value: text,
      label: text,
    });
    setText("");
  };

  const downloadHandler = (e) => {
    e.preventDefault();
    const conversations = [];

    for (let messsage of messages) {
      const { speaks, type, value } = messsage;

      for (const [key, val] of Object.entries(value)) {
        if (key === "text") {
          conversations.push({
            speaks,
            type: "text",
            value: val,
          });
        } else if (key === "chip") {
          if (val.question !== "") {
            conversations.push({
              speaks,
              type: "text",
              value: val.question,
            });
          }
        } else if (key === "ref") {
          if (val.question !== "") {
            conversations.push({
              speaks,
              type: "text",
              value: val.question,
            });
          }
        } else if (key === "videos") {
          conversations.push({
            speaks,
            type: "videos",
            value: val,
          });
        } else if (key === "articles") {
          conversations.push({
            speaks,
            type: "articles",
            value: val,
          });
        } else if (key === "courses") {
          conversations.push({
            speaks,
            type: "courses",
            value: val,
          });
        }
      }
    }

    const element = document.createElement("a");

    let convHtml = "";
    conversations.forEach((conv) => {
      if (conv.type === "text") {
        convHtml += `<div class="Message_Message ${
          conv.speaks === "bot"
            ? "Message_To justify-content-start"
            : "Message_From justify-content-start flex-row-reverse"
        } d-flex align-items-start">
        <div class="Message_Avatar">${
          conv.speaks === "bot"
            ? '<img src="http://localhost:3000/metabot.png" alt="bot" />'
            : "!"
        }</div>
        <div class="Message_MessageText">
        ${
          conv.speaks === "bot"
            ? '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " /></svg>'
            : '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="0,15.5 15.5,15.5 0,0 " /></svg>'
        }
          <div>${conv.value}</div>
        </div>
      </div>`;
      } else if (conv.type === "videos") {
        convHtml += `<div class="Message_Message ${
          conv.speaks === "bot"
            ? "Message_To justify-content-start"
            : "Message_From justify-content-start flex-row-reverse"
        } d-flex align-items-start">
        <div class="Message_Avatar">${
          conv.speaks === "bot"
            ? '<img src="http://localhost:3000/metabot.png" alt="bot" />'
            : "!"
        }</div>
        <div class="Message_MessageText">
        ${
          conv.speaks === "bot"
            ? '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " /></svg>'
            : '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="0,15.5 15.5,15.5 0,0 " /></svg>'
        }
        <div>${/*JSON.stringify(conv.value)*/ "Video"}</div>
        </div>
      </div>`;
      } else if (conv.type === "articles") {
        convHtml += `<div class="Message_Message ${
          conv.speaks === "bot"
            ? "Message_To justify-content-start"
            : "Message_From justify-content-start flex-row-reverse"
        } d-flex align-items-start">
        <div class="Message_Avatar">${
          conv.speaks === "bot"
            ? '<img src="http://localhost:3000/metabot.png" alt="bot" />'
            : "!"
        }</div>
        <div class="Message_MessageText">
        ${
          conv.speaks === "bot"
            ? '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " /></svg>'
            : '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="0,15.5 15.5,15.5 0,0 " /></svg>'
        }
        <div>${/*JSON.stringify(conv.value)*/ "Article"}</div>
        </div>
      </div>`;
      } else if (conv.type === "courses") {
        convHtml += `<div class="Message_Message ${
          conv.speaks === "bot"
            ? "Message_To justify-content-start"
            : "Message_From justify-content-start flex-row-reverse"
        } d-flex align-items-start">
        <div class="Message_Avatar">${
          conv.speaks === "bot"
            ? '<img src="http://localhost:3000/metabot.png" alt="bot" />'
            : "!"
        }</div>
        <div class="Message_MessageText">
        ${
          conv.speaks === "bot"
            ? '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " /></svg>'
            : '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.5 15.5" enableBackground="new 0 0 15.5 15.5"><polygon fill="#FFFFFF" points="0,15.5 15.5,15.5 0,0 " /></svg>'
        }
          <div>${/*JSON.stringify(conv.value)*/ "Course"}</div>
        </div>
      </div>`;
      }
    });
    const file = new Blob(
      [
        `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#190c40" />
        <title>Metaverse - Planet Media</title>
        <link rel="icon" href="https://gugan-gulwan.herokuapp.com/favicon.ico"/>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous">
        <style>
          body{
            margin: 0;
            font-family: 'Saira', sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #190c40;
            color: #ffffff;
          }
          .container{
            max-width: 900px;
            padding-top: 2rem;
            padding-bottom: 2rem;
          }

          .Message_Message {
            width: 100%;
            padding: 1rem 0;
          }
          @media (min-width: 768px) {
            .Message_Message {
              padding: 1rem 2rem;
            }
          }
          .Message_Avatar {
            width: 3rem;
            min-width: 3rem;
            height: 3rem;
            line-height: 3rem;
            border-radius: 50%;
            margin-right: 1rem;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.85);
            color: #190d3f;
            font-size: 1.2rem;
            font-weight: 500;
            position: relative;
            overflow: hidden;
          }
          .Message_Avatar img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
            }
          .Message_From .Message_Avatar {
            margin-left: 1rem;
            margin-right: 0;
            background-color: rgba(255, 255, 255, 0.1);
            color: #fff;
          }
          .Message_To {
          }
          .Message_MessageArea{
            width: 100%;
          }
          .Message_MessageText {
            min-width: 8rem;
            background-color: rgba(255, 255, 255, 0.85);
            color: #190d3f;
            border-radius: 0 1rem 1rem 1rem;
            padding: 1rem 0.75rem;
            position: relative;
          }
          @media (min-width: 768px) {
            .Message_MessageText {
              padding: 1rem;
            }
          }
          .Message_MessageText > svg {
            display: block;
            width: 15px;
            height: auto;
            position: absolute;
            top: 0;
            left: -15px;
          }
          .Message_MessageText > svg polygon {
            fill: rgba(255, 255, 255, 0.85);
          }
          .Message_From .Message_MessageText {
            margin-left: 4rem;
            background-color: rgba(255, 255, 255, 0.1);
            color: #fff;
            border-radius: 1rem 1rem 0 1rem;
          }
          .Message_From .Message_MessageText > svg {
            top: auto;
            left: auto;
            bottom: 0;
            right: -15px;
          }
          .Message_From .Message_MessageText > svg polygon {
            fill: rgba(255, 255, 255, 0.1);
          }
          .Message_MessageText.Message_MessageError {
            border-color: darkred;
            background-color: darkred;
            color: white;
          }
        </style>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap" rel="stylesheet">
      </head>
      <body>
        <div class="container">
          ${convHtml}
        </div>
      </body>
    </html>    
    `,
      ],
      {
        type: "text/html",
      }
    );
    element.href = URL.createObjectURL(file);
    const filename = element.href.split("/").pop();
    element.download = filename + ".html";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div className={styles.Chatbox}>
      <div className={styles.Content}>
        <Messages />
        <Suggestions />
      </div>
      <footer
        className={`${styles.Footer} d-flex align-items-center justify-content-between`}
      >
        <fieldset className={styles.Fieldset}>
          <form
            className="w-100 d-flex align-items-center"
            onSubmit={submitHandler.bind(this)}
          >
            <input
              type="text"
              placeholder="Type a message"
              ref={textInput}
              className={styles.Input}
              onChange={(e) => {
                const textValue = sanitize(e.target.value);
                setText(textValue);
              }}
              value={text}
              disabled={typing}
              // disabled={
              //   typing ||
              //   (Object.values(suggestions).length > 0 &&
              //     Object.values(suggestions).findIndex(
              //       (item) => item.type === undefined
              //     ) === -1)
              // }
            />
            <button
              type="submit"
              aria-label="Send Message"
              data-tip="Send Message"
              onMouseEnter={() => showTooltip(true)}
              onMouseLeave={() => {
                showTooltip(false);
                setTimeout(() => showTooltip(true), 50);
              }}
              className={styles.Button}
            >
              <Plane />
            </button>
          </form>
        </fieldset>
        <button
          type="button"
          aria-label="Start Over"
          data-tip="Start Over"
          onMouseEnter={() => showTooltip(true)}
          onMouseLeave={() => {
            showTooltip(false);
            setTimeout(() => showTooltip(true), 50);
          }}
          className={styles.Button}
          onClick={() => {
            clearUser();
          }}
        >
          <Refresh />
        </button>
        <button
          type="button"
          aria-label="Save Conversation"
          data-tip="Save Conversation"
          onMouseEnter={() => showTooltip(true)}
          onMouseLeave={() => {
            showTooltip(false);
            setTimeout(() => showTooltip(true), 50);
          }}
          className={styles.Button}
          onClick={downloadHandler.bind(this)}
        >
          <Save />
        </button>
      </footer>
      {tooltip && <ReactTooltip />}
    </div>
  );
}
