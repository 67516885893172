import React from "react";
import styles from "./Message.module.css";
import stylem from "./index.module.css";
import Avatar from "../../assets/metabot.png";

const Message = ({ speaks, value, children }) => {
  return (
    <div
      className={`${stylem.Message} ${
        speaks === "bot"
          ? stylem.To + " justify-content-start"
          : stylem.From + " justify-content-start flex-row-reverse"
      } d-flex align-items-start`}
    >
      {speaks === "bot" ? (
        <div className={stylem.Avatar}><img src={Avatar} alt="bot" /></div>
      ) : (
        <div className={stylem.Avatar}>!</div>
      )}
      <div
        className={`${stylem.MessageText} ${
          speaks === "error" ? stylem.MessageError : ""
        }`}
      >
        {speaks === "bot" ? (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 15.5 15.5"
            enableBackground="new 0 0 15.5 15.5"
          >
            <polygon fill="#FFFFFF" points="15.5,0 0,0 15.5,15.5 " />
          </svg>
        ) : (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 15.5 15.5"
            enableBackground="new 0 0 15.5 15.5"
          >
            <polygon fill="#FFFFFF" points="0,15.5 15.5,15.5 0,0 " />
          </svg>
        )}
        {value && <div dangerouslySetInnerHTML={{ __html: value }} />}
        {children}
      </div>
    </div>
  );
};

export default Message;
