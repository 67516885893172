import React from "react";
import ReactPlayer from "react-player/youtube";
import Modal from "react-modal";
import styles from "./Course.module.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    borderRadius: "0px",
    backgroundColor: "#121117"
  },
};
Modal.setAppElement('#root');

export default function Course({ course }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
        <div className={styles.Course}>
          <figure onClick={openModal}>
            <img
              src={`https://img.youtube.com/vi/${course.video_id}/hqdefault.jpg`}
              alt={course.title}
            />
            <svg
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 387.5 387.3"
              enableBackground="new 0 0 387.5 387.3"
              xmlSpace="preserve"
            >
              <g>
                <path
                  fill="#FFFFFF"
                  d="M205,387.3c-7.6,0-15.1,0-22.7,0c-5.1-0.6-10.2-1.1-15.3-1.8c-38.2-5.4-72-20.6-100.9-46.2
		C32.1,309.3,10.8,271.9,3,227c-1.3-7.3-2-14.6-3-22c0-7.6,0-15.1,0-22.7c0.2-1.2,0.6-2.4,0.7-3.6c1.2-17.5,4.8-34.4,10.8-50.8
		C45.3,34.6,143.7-18,240.2,5.6C339.7,29.9,403.5,129.5,384,230c-7.5,38.5-25,71.9-52.6,99.7c-28.9,29.2-63.7,47.6-104.3,54.6
		C219.8,385.6,212.4,386.4,205,387.3z M193.6,24.1C100,24.1,24.2,99.8,24,193.5c-0.2,93.6,76.1,169.8,169.7,169.8
		c93.6-0.1,169.4-75.8,169.6-169.5C363.6,100.3,287.3,24,193.6,24.1z"
                />
                <path
                  fill="#FFFFFF"
                  d="M144.9,118.7c43.3,25,86.2,49.8,129.8,75c-43.6,25.2-86.5,49.9-129.8,75
		C144.9,218.6,144.9,168.9,144.9,118.7z"
                />
              </g>
            </svg>
          </figure>
          <div className={styles.Title}>{course.title}</div>
        </div>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={course.title}
          >
            <button onClick={closeModal} className={styles.Close} aria-label="Close Modal"></button>
            <ReactPlayer
            url={`https://www.youtube.com/watch?v=${course.video_id}`}
            config={{
              youtube: {
                playerVars: { showinfo: 0 },
              }
            }}
          />
          </Modal>
        </>
  );
}
